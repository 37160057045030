<template>
  <div v-if="!hasPageError && (isPixEnabled || isCreditEnabled)" class="container">
    <spinner-component
      v-if="hasSpinner"
      :styles="spinner"
    />

    <div v-else class="checkout-container">
      <navbar-component
        v-if="!hideNavbar"
        class="header-component"
        :data="navbarData"
      />

      <div class="checkout-content">
        <div class="forms-container">
          <header-component
            :data="headerData"
          />

          <div class="checkout">
            <summary-card-component
              v-if="!isDesktop() && !checkoutStatus.isSuccessPayment"
              :isMobile="true"
              :hasRateOptions="hideRateDetails"
              :data="checkoutData"
            />

            <div v-if="getCheckoutStatus.isPayment">
              <div v-if="!getCheckoutStatus.isExpired">
                <p>
                  Escolha a melhor forma de pagamento pra você:
                </p>

                <div class="section">
                  <status-component
                    step="1"
                    :status="getFormStepStatus.firstStep"
                  />

                  <div class="content">
                    <payment-type-card
                      v-if="isPixEnabled"
                      :styles="pixPaymentCardStyles"
                      :isSelected="pixPaymentActive"
                      @click.native="setPaymentType('pix')"
                    />

                    <payment-type-card
                      v-if="isCreditEnabled"
                      :styles="creditCardStyles"
                      :isSelected="creditCardActive"
                      @click.native="setPaymentType('credit')"
                    />
                  </div>
                </div>

                <div v-if="creditCardActive" class="card-section">
                  <div class="section">
                    <status-component
                      step="2"
                      :status="getFormStepStatus.secondStep"
                    />

                    <to-pay-card-component
                      class="content"
                      :data="checkoutData"
                      :class="{ 'disable': getFormStepStatus.disableToPayForm }"
                    />
                  </div>

                  <div class="section">
                    <status-component
                      step="3"
                      :status="getFormStepStatus.thirdStep"
                    />

                    <user-data-card-component
                      class="content"
                      :isBlocked="getFormStepStatus.disableUserDataForm"
                      :class="{ 'disable': getFormStepStatus.disableUserDataForm }"
                    />
                  </div>

                  <div class="section">
                    <status-component
                      step="4"
                      :status="getFormStepStatus.fourthStep"
                      :hasLine="false"
                    />

                    <payment-method-card-component
                      class="content"
                      :isBlocked="getFormStepStatus.disablePaymentMethodForm"
                      :class="{ 'disable': getFormStepStatus.disablePaymentMethodForm }"
                    />
                  </div>

                  <div class="payment-button-container">
                    <button-component
                      text="Pagar"
                      :hasRequest="hasRequest"
                      :disableButton="getFormStepStatus.disableButton"
                      @click.native="submitForm"
                    />
                  </div>
                </div>

                <div v-else-if="pixPaymentActive" class="pix-section">
                  <div class="section">
                    <status-component
                      step="2"
                      :status="getFormStepStatus.secondStep"
                      :hasLine="false"
                    />

                    <pix-payment-card-component
                      class="content"
                      :value="checkoutData.grand_total"
                    />
                  </div>
                </div>

                <div v-else>
                  <div class="section">
                    <status-component
                      step="2"
                      :hasLine="false"
                    />

                    <default-payment-card-component
                      class="content disable"
                      :value="checkoutData.grand_total"
                    />
                  </div>
                </div>
              </div>

              <div v-else>
                <card-component
                  :styles="expiredPaymentCardData"
                />
              </div>
            </div>

            <div v-else>
              <card-component
                v-if="checkoutStatus.isSuccessPayment"
                :styles="getSuccessPaymentCardData"
              />

              <card-component
                v-else-if="checkoutStatus.isExpired"
                :styles="expiredPaymentCardData"
              />

              <card-component
                v-else-if="checkoutStatus.isPixPaymentError"
                :styles="errorPixPaymentCardData"
              />

              <card-component
                v-else
                :styles="errorPaymentCardData"
              />
            </div>
          </div>
        </div>

        <summary-card-component
          v-if="isDesktop() && !checkoutStatus.isSuccessPayment"
          :hasRateOptions="hideRateDetails"
          :data="checkoutData"
          class="summary-card"
        />
      </div>

      <footer-component
        v-if="!hideFooter"
        :data="footerData"
      />
    </div>
  </div>

  <div v-else class="error-card-container">
    <card-component
      class="error-card"
      :styles="errorCardData"
    />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import Navbar from '@/components/base/Navbar';
  import Footer from '@/components/base/Footer';
  import SummaryCard from '@/components/SummaryCard';
  import ToPayCard from '@/components/ToPayCard';
  import UserDataCard from '@/components/UserDataCard';
  import PaymentMethodCard from '@/components/PaymentMethodCard';
  import Spinner from '@/components/Spinner';
  import PaymentTypeCard from '@/components/PaymentTypeCard';
  import Header from '@/components/Header';
  import Button from '@/components/Button';
  import Status from '@/components/Status';
  import PixPaymentCard from '@/components/PixPaymentCard';
  import DefaultPaymentCard from '@/components/DefaultPaymentCard';
  import Card from '@/components/Card';

  export default {
    name: 'CustomCheckout',

    components: {
      'navbar-component': Navbar,
      'footer-component': Footer,
      'summary-card-component': SummaryCard,
      'to-pay-card-component': ToPayCard,
      'user-data-card-component': UserDataCard,
      'payment-method-card-component': PaymentMethodCard,
      'spinner-component': Spinner,
      'payment-type-card': PaymentTypeCard,
      'header-component': Header,
      'button-component': Button,
      'status-component': Status,
      'pix-payment-card-component': PixPaymentCard,
      'default-payment-card-component': DefaultPaymentCard,
      'card-component': Card,
    },

    data() {
      return {
        width: window.innerWidth,
        isPixEnabled: false,
        isCreditEnabled: true,
        hasRequest: false,
        checkoutIsExpired: false,
        pixPaymentActive: false,
        creditCardActive: false,
        checkoutData: {},
        hideRateDetails: false,
        hideNavbar: false,
        hideFooter: false,
        link: '',

        spinner: {
          size: '10px',
          color: '#feaa3b',
          borderSize: '1.1em',
        },

        headerData: {
          text: '',
          description: {
            label: '',
            value: ''
          },
          lineColor: '#DADADA',
          textColor: '#6D6F6F',
          descriptionColor: '#6D6F6F'
        },

        navbarData: {
          backgroundColor: '',
          logoPath: 'logo-impay.png',
          isExternalLogo: false,
          borderColor: '',
          text: ''
        },

        footerData: {
          backgroundColor: '',
          name: ''
        },

        expiredPaymentCardData: {
          icon: 'icon-checkout-error',
          backgroundIcon: '#b81d5b',
          title: 'Link de pagamento expirado!',
          subTitle: 'Mas não se preocupe, entre em contato com a sua unidade Instituto Mix e solicite um novo link para efetuar seu pagamento.'
        },

        errorPaymentCardData: {
          icon: 'icon-checkout-error',
          backgroundIcon: '#b81d5b',
          title: 'Não conseguimos efetuar seu pagamento no momento',
          subTitle: 'Por favor, entre em contato o com seu banco para melhor entendimento e em seguida',
          items: {
            title: 'Os erros mais comuns apresentados são:',
            itemsArray: [
              'Cartão bloqueado',
              'Compra não autorizada pelo banco',
              'Saldo/limite indisponível'
            ]
          },
          link: '',
          footerText: 'Caso o erro persistir, favor entrar em contato com sua unidade Instituto Mix'
        },

        errorPixPaymentCardData: {
          icon: 'icon-checkout-error',
          backgroundIcon: '#b81d5b',
          title: 'Não conseguimos gerar um pagamento via Pix no momento',
          subTitle: 'Por favor, espere alguns minutos e em seguida',
          link: '',
        },

        errorCardData: {
          icon: 'icon-checkout-error',
          backgroundIcon: '#b81d5b',
          title: 'Encontramos um erro',
          subTitle: 'Entre em contato com o emissor ou',
          link: ''
        },

        creditCardStyles: {
          icon: 'icon-credit-card',
          text: 'Cartão'
        },

        pixPaymentCardStyles: {
          icon: 'icon-pix',
          text: 'Pix'
        }
      };
    },

    computed: {
      ...mapState({
        paymentData: state => state.data,
        hasPageError: state => state.hasError,
        hasSpinner: state => state.hasSpinner,
        formStepStatus: state => state.formStepStatus,
        checkoutStatus: state => state.checkoutStatus,
        successPaymentCardData: state => state.successPaymentCardData,
        qrCodeIsExpired: state => state.qrCodeIsExpired
      }),

      ...mapGetters([
        'getCurrentCheckoutId',
        'getFormStepStatus',
        'getCheckoutStatus',
        'getSuccessPaymentCardData'
      ]),
    },

    watch: {
      width() {
        this.isDesktop();
      }
    },

    created() {
      this.hideNavbar = !!this.$route.query.hideNavbar;
      this.hideFooter = !!this.$route.query.hideFooter;

      window.onresize = () => {
        this.width = window.innerWidth;
      };

      const checkoutId = this.$route.params.checkoutId;
      this.$store.commit('setCurrentCheckoutId', checkoutId);

      this.fetchData();
    },

    mounted() {
      this.link = this.$route.fullPath;

      this.errorPaymentCardData.link = this.link;
      this.errorCardData.link = this.link;
      this.errorPixPaymentCardData.link = this.link;

      this.$store.commit('changeSpinnerStatus', true);
      this.$store.commit('resetInstallmentsData');
    },

    methods: {
      isDesktop() {
        return this.width > 950;
      },

      isImResource(resource) {
        const resources = ['CHECKOUTERP', 'CHECKOUTEDITORA', 'CHECKOUTPRECONTRATO', 'CHECKOUTSMS', 'CHECKOUTEJA'];

        return resources.includes(this.$utils.toUpperCase(resource));
      },

      hasContractDescription(resource) {
        let resources = ['CHECKOUTERP', 'CHECKOUTPRECONTRATO'];

        return resources.includes(this.$utils.toUpperCase(resource));
      },

      submitForm() {
        this.$store.commit('disableAllForms');
        this.setPayment();
      },

      setPaymentType(type) {
        this.$store.commit('resetFormStatus');
        this.$store.commit('resetData');
        this.$store.commit('resetInstallmentsData');

        if (this.$utils.toUpperCase(type) == 'PIX') {
          this.pixPaymentActive = true;
          this.creditCardActive = false;
          this.formStepStatus.firstStep = 'check';
          this.formStepStatus.secondStep = 'wait';
        } else {
          this.creditCardActive = true;
          this.pixPaymentActive = false;
          this.formStepStatus.firstStep = 'check';
          this.formStepStatus.secondStep = 'wait';
        }
      },

      hasRateDetails(dailyInterestRate, discountRate) {
        return dailyInterestRate > 0 || discountRate > 0;
      },

      fetchData() {
        this.$store.dispatch('getCheckout')
          .then(res => {
            this.$store.commit('changeErrorStatus', false);

            this.checkoutData = res.data;

            let metadata = this.checkoutData.metadata;
            let isImResource = metadata?.resource && this.isImResource(metadata.resource);
            let isGerenciaAiResource = metadata?.resource && this.$utils.toUpperCase(metadata.resource) == 'GERENCIA_AI';
            let customSettings = this.checkoutData.custom_settings;

            this.isCreditEnabled = this.checkoutData.is_credit_enabled;
            this.isPixEnabled = metadata?.is_pix_enabled ? this.$utils.toBoolean(metadata.is_pix_enabled) : this.checkoutData.is_pix_enabled;

            this.hideRateDetails = this.hasRateDetails(this.checkoutData.daily_interest_rate, this.checkoutData.on_time_payment_discount_rate);
            this.checkoutStatus.isSuccessPayment = false;
            this.checkoutStatus.isExpired = this.checkoutData.is_expired;

            if (this.checkoutData.transaction != null && this.$utils.toUpperCase(this.checkoutData.transaction.status) == 'SUCCEEDED') {
              this.successPaymentCardData.transaction.id = this.checkoutData.transaction_id;
              this.successPaymentCardData.transaction.date = this.checkoutData.paid_at ? this.$utils.dateFormat(this.checkoutData.paid_at, 'DD/MM/YYYY HH:mm:ss') : '';
              this.checkoutStatus.isSuccessPayment = true;
              this.checkoutStatus.isPayment = false;
            }

            if (isImResource) {
              this.$store.commit('setFaviconPath', '/favicon-instmix.ico');
              this.setPageTitle('Instituto Mix');

              this.navbarData = this.navBarStyle('#AA1428', 'logo-instMix.png', false, '#FF9C00', 'Sistema de pagamento digital');
              this.headerData = this.headerStyle('#FF9C00', '#AA1428', '#6D6F6F');
              this.footerData = this.footerStyle('#AA1428', 'Instituto Mix');
            } else if (customSettings || isGerenciaAiResource) {
              this.$store.commit('setFaviconPath', '/favicon-gerencia.ico');

              const resourceColor = isGerenciaAiResource ? '#272A31' : '#435970';
              let resourceLogo = 'logo-impay.png';

              const hasLogo = customSettings?.logo;
              const defaultColor = customSettings?.default_color || resourceColor;
              const isLightDefaultColor = this.hexColorBrightness(defaultColor) == 'light';

              if (isGerenciaAiResource) {
                resourceLogo = isLightDefaultColor ? 'logo-gerencia-light.svg' : 'logo-gerencia-dark.svg';
              }

              const logoUrl = hasLogo ? customSettings.logo.url : resourceLogo;
              const footerFontColor = isLightDefaultColor ? '#FFFFFF' : '#272A31';

              this.navbarData = this.navBarStyle(defaultColor, logoUrl, hasLogo, '#DADADA33');
              this.headerData = this.headerStyle('#DADADA', '#6D6F6F', '#6D6F6F');
              this.footerData = this.footerStyle(defaultColor, 'Gerencia AI', footerFontColor);
            } else {
              this.$store.commit('setFaviconPath', '/favicon-impay.ico');
              this.setPageTitle('IM Pay');

              this.navbarData = this.navBarStyle('#435970', 'logo-impay.png');
              this.headerData = this.headerStyle('#DADADA', '#6D6F6F', '#6D6F6F');
              this.footerData = this.footerStyle('#435970', 'IM Pay');
            }

            this.headerData.text = this.checkoutData.customer_name;

            this.headerData.description.label = metadata?.resource && this.hasContractDescription(metadata.resource) ? 'Contrato' : '';
            this.headerData.description.value = this.checkoutData.description;
          })
          .catch(() => {
            this.$store.commit('changeErrorStatus', true);
          })
          .finally(() => {
            this.$store.commit('changeSpinnerStatus', false);
            parent.postMessage('impay-checkout-loaded', '*');
          });
      },

      async setPayment() {
        this.hasRequest = true;
        this.checkoutStatus.isSuccessPayment = false;

        const timeZoneOffset = new Date().getTimezoneOffset();
        const expirationMonth = this.paymentData.dateValidate.substr(0, 2);
        const expirationYear = this.$utils.formatYear(this.paymentData.dateValidate.substr(3, 5), 'YY', 'YYYY');
        const body = {
          payment: {
            method: 'CreditCard',
            credit_card: {
              number: this.paymentData.cardNumber,
              holder_name: this.paymentData.fullName,
              expiration_month: expirationMonth,
              expiration_year: expirationYear,
              security_code: this.paymentData.securityCode,
              installments: {
                count: this.paymentData.installments.numberOfInstallments
              },
              three_d_secure: {
                device: {
                  screen_height: window.innerHeight,
                  screen_width: window.innerWidth,
                  language: window.navigator.language,
                  color_depth: window.screen.colorDepth,
                  time_zone_offset: timeZoneOffset / 60,
                },
                billing: {
                  postal_code: this.paymentData.postalCode,
                  address: this.paymentData.address,
                  city: this.paymentData.city,
                  state: this.paymentData.state,
                  phone_number: this.paymentData.phone,
                  email: this.paymentData.email
                }
              }
            }
          },
        };

        await this.$store.dispatch('makePayment', JSON.stringify(body))
          .then(res => {
            const currentDate = new Date();

            if (!res.data || !res.data.transaction_id) {
              this.checkoutStatus.isSuccessPayment = false;
              this.checkoutStatus.isPayment = false;
              return;
            }

            this.checkoutStatus.isSuccessPayment = true;
            this.successPaymentCardData.transaction.id = res.data.transaction_id;
            this.successPaymentCardData.transaction.date = this.$utils.dateFormat(currentDate, 'DD/MM/YYYY HH:mm:ss');
          })
          .catch(err => {
            this.checkoutStatus.isExpired = false;
            if (err.data && err.data.status == 419) {
              this.checkoutStatus.isExpired = true;
            }
          });

        this.checkoutStatus.isPayment = false;
        this.hasRequest = false;
      },

      setPageTitle(complement) {
        window.document.title = this.$router.history.current.meta.title + ' | ' + complement;
      },

      navBarStyle(backgroundColor, logoPath, isExternalLogo = false, borderColor, text) {
        return {
          backgroundColor,
          logoPath,
          borderColor,
          text,
          isExternalLogo
        };
      },

      headerStyle(lineColor, textColor, descriptionColor) {
        return {
          text: '',
          description: {
            label: '',
            value: ''
          },
          lineColor: lineColor,
          textColor: textColor,
          descriptionColor: descriptionColor
        };
      },

      footerStyle(backgroundColor, name, fontColor) {
        return {
          backgroundColor,
          name,
          fontColor
        };
      },

      hexColorBrightness(hex) {
        hex = hex.replace(/^#/, '');

        var r = parseInt(hex.substring(0, 2), 16) / 255;
        var g = parseInt(hex.substring(2, 4), 16) / 255;
        var b = parseInt(hex.substring(4, 6), 16) / 255;

        var luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

        return luminance > 0.5 ? 'dark' : 'light';
      },
    }
  };
</script>

<style lang="scss" scoped>
  .container {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    min-height: 100vh;

    .checkout-container {
      flex-direction: column;
      align-items: center;
      min-height: 100vh;
      display: flex;
      width: 100%;

      .header-component {
        width: 100%;
      }

      .checkout-content {
        margin-bottom: 2rem;
        padding: 0 1.5rem;
        margin-top: 3rem;
        display: flex;
        width: 1440px;

        .forms-container {
          width: 100%;

          .checkout {
            p {
              font-size: 16px;
            }

            .section {
              margin-top: 2rem;
              display: flex;

              .content {
                display: flex;
                width: 100%;

                &.disable {
                  pointer-events: none;
                  filter: blur(1.5px);
                  user-select: none;
                  opacity: 0.4;
                }
              }
            }

            .payment-button-container {
              margin-top: 1.5rem;

              button {
                margin-left: auto;
              }
            }
          }
        }

        .summary-card {
          min-width: 450px;
          margin-left: 1.5rem;
        }
      }
    }
  }

  .error-card-container {
    justify-content: center;
    padding: 2rem;
    display: flex;

    .error-card {
      width: 70rem;
    }
  }

  @media (max-width: 1440px) {
    .container {
      .checkout-container {
        .checkout-content {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 520px) {
    .container {
      font-size: 14px;

      .checkout-container {
        .checkout-content {
          justify-content: unset;
          margin-top: 1rem;

          .forms-container {
            .checkout {
              p {
                font-size: 14px;
              }

              .payment-button-container {
                justify-content: center;
                align-items: center;
                margin-left: unset;
                display: flex;

                button {
                  margin-left: unset;
                }
              }
            }
          }
        }
      }
    }
  }
</style>